import axios, { AxiosRequestHeaders } from 'axios';
import { LOCKSERVER_NEW } from './core/constants';
import { LockWizardApi } from './idpAuth';
interface LockIdResult {
    error?: string;
    lockId?: string;
    guestCode?: string;
    ownerCode?: string;
    oneTimeCode?: string;
}

export class TkApi {
    /**
     * Build headers with Bearer token
     */

    async getHeaders(): Promise<AxiosRequestHeaders> {
        const tokenService = new LockWizardApi();
        //const token = AuthService.idpToken;
        const token = await tokenService.getToken();

        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
    }

    async listLocks(
        offset: number,
        limit: number,
        includeInactive: boolean,
        orderBy: string,
        order: string,
    ): Promise<any> {
        return new Promise<LockIdResult | any[]>(async (resolve, _reject) => {
            let ret: any = {};
            const url = `${LOCKSERVER_NEW}/v1/lock/list`;
            const params = {
                offset: offset,
                limit: limit,
                include_inactive: includeInactive,
                order_by: orderBy,
                order: order,
            };
            try {
                const res = await axios.get(url, {
                    headers: await this.getHeaders(),
                    params: params,
                });
                console.log('get lock list return', res.data.data);
                const results = res.data.data.records;
                //got to format the results to be an array
                const resultsFormatted = Object.keys(results).map((key) => results[key]);
                ret = resultsFormatted;
            } catch (err: any) {
                ret.error = err.response.data.message;
                console.log('list locks error was caught: ', err);
            }
            resolve(ret);
        });
    }
    //GET https://tklockapi.smart-home.vacasastage.io/v1/lock/get?private_master_code=111111&include_inactive=true
    async getLockById(lockid: string, includeInactive = false): Promise<any> {
        return new Promise<LockIdResult>(async (resolve, _reject) => {
            let ret: LockIdResult = {};
            const url = `${LOCKSERVER_NEW}/v1/lock/get`;
            const params = {
                id: lockid,
                include_inactive: includeInactive,
            };
            try {
                const res = await axios.get(url, {
                    headers: await this.getHeaders(),
                    params: params,
                });
                console.log('get lock by id return', res.data);
                ret = res.data.data;
            } catch (err: any) {
                ret.error = err.response.data.message;
            }
            resolve(ret);
        });
    }
    //GET https://tklockapi.smart-home.vacasastage.io/v1/lock/get?private_master_code=111111
    async getLockByFilter(field: string, val: string): Promise<any> {
        return new Promise<LockIdResult>(async (resolve, _reject) => {
            let ret: any = {};
            const url = `${LOCKSERVER_NEW}/v1/lock/get?${field}=${val}`;
            try {
                const res = await axios.get(url, { headers: await this.getHeaders() });
                console.log('get lock by id return', res.data);
                ret = res.data.data;
            } catch (err: any) {
                ret = null;
            }
            resolve(ret);
        });
    }

    async getLockByLockNum(lock_num: string, includeInactive = false): Promise<any> {
        return new Promise<LockIdResult>(async (resolve, _reject) => {
            const ret: LockIdResult = {};
            const url = `${LOCKSERVER_NEW}/v1/lock/get`;
            const body = {
                lock_number: lock_num,
                include_inactive: includeInactive,
            };
            try {
                const res = await axios.post(url, body, { headers: await this.getHeaders() });
                console.log('get lock by lock_num return', res.data);
                ret.lockId = res.data.data.id;
            } catch (err: any) {
                ret.error = err.response.data.message;
            }
            resolve(ret);
        });
    }

    async deleteLock(lock_id: string): Promise<any> {
        const lockService = new LockWizardApi();
        await lockService.removeLock(lock_id);
        return;
    }

    async getCodeByLock(lockid: string, offset: number, limit: number): Promise<any> {
        return new Promise<LockIdResult | any[]>(async (resolve, _reject) => {
            let ret: any = {};
            const url = `${LOCKSERVER_NEW}/v1/record/list`;
            const params = {
                lock_id: lockid,
                offset: offset,
                limit: limit,
            };
            try {
                const res = await axios.get(url, {
                    headers: await this.getHeaders(),
                    params: params,
                });
                console.log('get access codes by lock: ', res.data.data);
                const results = res.data.data.records;
                //got to format the results to be an array
                const resultsFormatted = Object.keys(results).map((key) => results[key]);
                ret = resultsFormatted;
            } catch (err: any) {
                ret.error = err.response.data.message;
            }
            resolve(ret);
        });
    }

    async decodeAccessCode(lockid: string, accessCode: string): Promise<any> {
        return new Promise<LockIdResult | any[]>(async (resolve, _reject) => {
            let ret: any = {};
            const url = `${LOCKSERVER_NEW}/v1/code/decode`;
            const params = {
                lock_id: lockid,
                access_code: accessCode,
                no_check: true,
            };
            try {
                const res = await axios.get(url, {
                    headers: await this.getHeaders(),
                    params: params,
                });
                console.log('decode AccessCodes: ', res.data.data);
                ret = res.data.data;
            } catch (err: any) {
                ret.error = err.response.data.message;
            }
            resolve(ret);
        });
    }
}

export default TkApi;
