import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { GridApi, ColumnApi, GetRowIdParams } from 'ag-grid-community';
import AccessCodesModal from './accessCodesModal';
import DecodeAccessCode from './DecodeAccessCode';
import AgGrid from './AgGrid';
import SearchField from './SearchField';
import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Optional theme CSS
import '../css/App.css';
import '../css/bootstrap.min.css';
import { ImplicitFlow } from '@vacasa/react-components-lib';
import {
    ENVIRONMENT,
    IDP_AUDIENCE,
    IDP_CLIENT_ID,
    IDP_REDIRECT_URI,
    IDP_SCOPES,
} from '../core/constants';

type lockInfo = {
    site_address: string;
    vendor_lockid: string;
    created_at: string;
    installed_by: string;
    timezone: string;
    lock_number: string;
    lock_name: string;
    private_master_code: string;
    vacasa_unit_id: string;
    id: string;
    base_dt: string;
    future_codes: [lockCode] | any | null;
};

export type lockCode = {
    accessCode: string;
    codeCategory: string;
    guestName: string;
    vacasaUnitCode: string;
    start: string;
    end: string;
    createdAt: string;
    createdBy: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface GridReadyEvent {
    api: GridApi;
    columnApi: ColumnApi;
    // Event identifier
    type: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface FilterModel {
    value: string;
    column: string;
    type: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface PaginationChangedEvent {
    // True if rows were animated to new position
    animate?: boolean;
    // True if rows were kept (otherwise complete redraw)
    keepRenderedRows?: boolean;
    // True if data was new (i.e user set new data)
    newData?: boolean;
    // True if user went to a new page
    newPage: boolean;
    api: GridApi;
    columnApi: ColumnApi;
    // Event identifier
    type: string;
}

type lockInfoArray = [lockInfo] | null | any;

export const TkGrid: React.FC<lockInfoArray> = (_props) => {
    const [resetFilters, doResetFilters] = useState(0);
    const [dataSetFilterApplied, setDataSetFilterApplied] = useState<string>('');
    const [showFutureCodes, setShowFutureCodes] = useState(false);
    const [currentUnitId, setCurrentUnitId] = useState<string | null>(null); //for the access code modal header
    const [currentLockId, setCurrentLockId] = useState<string | null>(null); //for the access code modal header

    const handleCloseFutureCodes = () => setShowFutureCodes(false);

    const handleShowFutureCodes = (lockId: string, unitId: string | null) => {
        setShowFutureCodes(true);
        setCurrentUnitId(unitId);
        setCurrentLockId(lockId);
    };

    const getRowId = (params: GetRowIdParams) => {
        return params.data.id;
    };

    const clearFilterBtnListener = () => {
        if (dataSetFilterApplied !== '') {
            setDataSetFilterApplied('');
        }

        doResetFilters((prev) => prev + 1);
    };

    if (ENVIRONMENT === 'local') {
        return (
            <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
                <div className="lock-info-header" style={{ height: '100px', padding: '5px' }}>
                    <h4 style={{ marginRight: '20px', marginLeft: '20px' }}>Turnkey Lock Info</h4>
                    <SearchField
                        dataSetFilter={dataSetFilterApplied}
                        setDataSetFilter={setDataSetFilterApplied}
                    />
                    <Button
                        variant="info"
                        onClick={clearFilterBtnListener}
                        style={{ display: 'inline', marginBottom: '10px' }}
                    >
                        Clear Filters
                    </Button>
                    <DecodeAccessCode />
                </div>
                <div>
                    <AgGrid
                        getRowId={getRowId}
                        handleShowFutureCodes={handleShowFutureCodes}
                        dataSetFilter={dataSetFilterApplied}
                        resetFilters={resetFilters}
                    />
                </div>
                <AccessCodesModal
                    show={showFutureCodes}
                    unitId={currentUnitId}
                    lockId={currentLockId}
                    hideHandler={handleCloseFutureCodes}
                />
            </div>
        );
    } else {
        return (
            <ImplicitFlow
                directoryHint="onelogin"
                clientId={IDP_CLIENT_ID}
                redirectUrl={IDP_REDIRECT_URI}
                scopes={IDP_SCOPES}
                audience={IDP_AUDIENCE}
                // on local authenticate on stage
                env={ENVIRONMENT}
                followRedirect={false}
            >
                <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
                    <div className="lock-info-header" style={{ height: '100px', padding: '5px' }}>
                        <h4 style={{ marginRight: '20px', marginLeft: '20px' }}>
                            Turnkey Lock Info
                        </h4>
                        <SearchField
                            dataSetFilter={dataSetFilterApplied}
                            setDataSetFilter={setDataSetFilterApplied}
                        />
                        <Button
                            variant="info"
                            onClick={clearFilterBtnListener}
                            style={{ display: 'inline', marginBottom: '10px' }}
                        >
                            Clear Filters
                        </Button>
                        <DecodeAccessCode />
                    </div>
                    <div>
                        <AgGrid
                            getRowId={getRowId}
                            handleShowFutureCodes={handleShowFutureCodes}
                            dataSetFilter={dataSetFilterApplied}
                            resetFilters={resetFilters}
                        />
                    </div>
                    <AccessCodesModal
                        show={showFutureCodes}
                        unitId={currentUnitId}
                        lockId={currentLockId}
                        hideHandler={handleCloseFutureCodes}
                    />
                </div>
            </ImplicitFlow>
        );
    }
};
