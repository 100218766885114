/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button } from 'react-bootstrap';
import React, { useState, useRef, useEffect } from 'react';
import { GridApi, ColumnApi, GetRowIdParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { DateTime } from 'luxon';
import { lockCode } from './TkGrid';
import LockCodeHide from './lockCodeHide';
import TkApi from '../tkApi';
import { Reference } from 'eslint-scope';
import { ADMIN_URL } from '../core/constants';
import ReactTooltip from 'react-tooltip';

export type FutureCodesModalProps = {
    getRowId: (params: GetRowIdParams) => any;
    handleShowFutureCodes: (lockId: string, unitId: string) => void;
    dataSetFilter: string;
    resetFilters: number;
};

type lockInfo = {
    site_address: string;
    vendor_lockid: string;
    created_at: string;
    installed_by: string;
    timezone: string;
    lock_number: string;
    lock_name: string;
    private_master_code: string;
    vacasa_unit_id: string;
    id: string;
    base_dt: string;
    future_codes: [lockCode] | any | null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface GridReadyEvent {
    api: GridApi;
    columnApi: ColumnApi;
    // Event identifier
    type: string;
}

interface FilterModel {
    value: string;
    column: string;
    type: string;
}
enum SearchType {
    guid,
    lock_id,
    pmc,
    normal,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface SortChangedEvent<TData = any> {
    // Source of the sort change.
    source: string;
    // The grid api.
    api: any;
    // The column api.
    columnApi: ColumnApi;
    // Application context as set on `gridOptions.context`.
    context: any;
    // Event identifier
    type: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface filterableData {
    vacasa_unit_id: string;
    id: string;
    lock_number: string;
    lock_name: string;
    installed_by: string;
    site_address: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface PaginationChangedEvent {
    // True if rows were animated to new position
    animate?: boolean;
    // True if rows were kept (otherwise complete redraw)
    keepRenderedRows?: boolean;
    // True if data was new (i.e user set new data)
    newData?: boolean;
    // True if user went to a new page
    newPage: boolean;
    api: GridApi;
    columnApi: ColumnApi;
    // Event identifier
    type: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type lockInfoArray = [lockInfo] | null | any;

const AgGrid: React.FC<FutureCodesModalProps> = (props) => {
    const GUID_REGEX =
        /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/;
    const LOCKID_REGEX = /^locknum:[0-9]{5}$/;
    const PMC_REGEX = /^pmc:[0-9]{6}$/;
    const rowsToDisplay = 100;
    const rowsFromAPI = 1000;
    const gridRef: any = useRef();
    const TkGridApi = new TkApi();
    let allRowsCount = 0;

    const [orderBy, setorderBy] = useState<string>('id');
    const [sortOrder, setSortOrder] = useState<string>('desc');

    useEffect(() => {
        clearGridFilters(); //children function of interest
    }, [props.resetFilters]);

    //parses date, expects ISO-8601 format
    const dateFormatter = (params: any) => {
        if (params.value) {
            let dateTimeObj = DateTime.fromISO(params.value, { zone: 'UTC' });
            if (params.data.timezone) {
                //if we get passed a timezone for the unit convert to that tz
                dateTimeObj = dateTimeObj.setZone(params.data.timezone);
            }
            return (
                dateTimeObj.toLocaleString(DateTime.DATETIME_SHORT) +
                ' ' +
                dateTimeObj.offsetNameShort
            );
        }
        return '';
    };
    const RenderWithToolTip = (params: any, _ref: Reference) => {
        return (
            <div className="overflowelipse">
                <a data-tip={params.value}> {params.value} </a>
                <ReactTooltip place="right" type="dark" effect="float" />
            </div>
        );
    };

    const RenderCodeBtn = (params: any, _ref: Reference) => {
        const rowData = params.data;
        if (rowData && 'id' in rowData && 'vacasa_unit_id' in rowData) {
            const lockId = rowData.id;
            const unitId = rowData.vacasa_unit_id;
            return (
                <span className="my-renderer">
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => props.handleShowFutureCodes(lockId, unitId)}
                    >
                        Access Codes
                    </Button>
                </span>
            );
        } else {
            //if we dont get a lock num for any reason disable the access code btn
            <span className="my-renderer">
                <Button variant="primary" size="sm" disabled>
                    Access Codes
                </Button>
            </span>;
        }
    };

    const RenderDeleteBtn = (params: any) => {
        const rowData = params.data;
        let lockId = '';
        if (rowData && 'id' in rowData) {
            lockId = rowData.id;
            return (
                <span className="my-renderer">
                    <Button variant="danger" size="sm" onClick={() => handleDeleteLock(lockId)}>
                        Delete
                    </Button>
                </span>
            );
        } else {
            //if we dont get a lock num for any reason disable the access code btn
            <span className="my-renderer">
                <Button variant="danger" size="sm" disabled>
                    Delete
                </Button>
            </span>;
        }
    };

    const RenderUnitIdLink = (params: any) => {
        const rowData = params.data;
        let unitId = '';
        if (rowData && 'vacasa_unit_id' in rowData) {
            unitId = rowData.vacasa_unit_id;
            return (
                <span className="my-renderer">
                    <a href={`${ADMIN_URL}/admin/dashboard/units/smartHome?UnitID=${unitId}`}>
                        {unitId}
                    </a>
                </span>
            );
        } else {
            <span className="my-renderer"></span>;
        }
    };

    const handleDeleteLock = async (lockId: string) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure you want to delete this lock?')) {
            await TkGridApi.deleteLock(lockId);
            gridRef.current.api.refreshInfiniteCache();
            //console.log("delete lock request sent", deleteResponse)
        }
    };

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: 'Vacasa Lock ID',
            field: 'id',
            width: 330,
            filter: 'agTextColumnFilter',
            sortable: true,
            filterParams: {
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
            },
        },
        {
            headerName: 'Vacasa Unit',
            field: 'vacasa_unit_id',
            width: 140,
            filter: 'agTextColumnFilter',
            cellRenderer: RenderUnitIdLink,
            filterParams: {
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
            },
        },
        {
            headerName: 'Lock #',
            field: 'lock_number',
            width: 100,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
            },
        },
        {
            headerName: 'Lock Name',
            field: 'lock_name',
            width: 150,
            cellRenderer: RenderWithToolTip,
            filter: 'agTextColumnFilter',
            sortable: true,
            filterParams: {
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
            },
        },
        {
            headerName: 'Base Datetime',
            field: 'base_dt',
            width: 210,
            valueFormatter: dateFormatter,
        },
        {
            headerName: 'Contact',
            field: 'installed_by',
            width: 120,
            cellRenderer: RenderWithToolTip,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
            },
        },
        {
            headerName: 'Installed On',
            field: 'created_at',
            width: 210,
            valueFormatter: dateFormatter,
            sortable: true,
        },
        {
            headerName: 'PMC',
            field: 'private_master_code',
            width: 140,
            cellRenderer: LockCodeHide,
        },

        {
            headerName: 'Access Codes',
            field: 'access_codes',
            width: 170,
            cellRenderer: RenderCodeBtn,
        },
        {
            headerName: 'Delete Lock',
            field: 'delete_lock',
            width: 120,
            cellRenderer: RenderDeleteBtn,
        },
    ]);

    const getGridData = async (offset: number) => {
        const tkData = await TkGridApi.listLocks(
            offset * rowsFromAPI,
            rowsFromAPI,
            false,
            orderBy,
            sortOrder,
        );
        if (tkData && !('error' in tkData)) {
            return tkData;
        }
        return [];
    };

    const dataSource = {
        //what the grid calls to get the data from the api
        rowCount: undefined,

        getRows: async (params: any) => {
            gridRef.current!.api.showLoadingOverlay();
            const filters = gatherFilters();
            let searchType: SearchType = SearchType.normal;
            if (props.dataSetFilter) {
                if (GUID_REGEX.test(props.dataSetFilter)) {
                    searchType = SearchType.guid;
                }
                if (LOCKID_REGEX.test(props.dataSetFilter)) {
                    searchType = SearchType.lock_id;
                }
                if (PMC_REGEX.test(props.dataSetFilter)) {
                    searchType = SearchType.pmc;
                }
            }

            switch (searchType) {
                case SearchType.guid:
                    getOneRecord(props.dataSetFilter, 'id', params);
                    break;
                case SearchType.pmc:
                    getOneRecord(props.dataSetFilter.split(':')[1], 'private_master_code', params);
                    break;
                case SearchType.lock_id:
                    getOneRecord(props.dataSetFilter.split(':')[1], 'lock_number', params);
                    break;

                default:
                    getGeneralData(filters, params);
                    break;
            }
        },
    };
    const getOneRecord = async (qs: any, field: string, params: any) => {
        const data: any[] = [];
        const lock = await TkGridApi.getLockByFilter(field, qs);
        if (lock) data.push(lock);
        gridRef.current!.api.hideOverlay();
        if (data.length === 0) {
            gridRef.current!.api.showNoRowsOverlay();
        }
        params.successCallback(data, data.length);
    };
    const getGeneralData = async (filters: any[], params: any) => {
        const filterPageOffset = 0; //if our filters return less than our desired #rows then we need to refetch
        let accumulatedData: any[] = [];
        let incomingData;
        let pageNum =
            gridRef.current.api.paginationGetCurrentPage() / (rowsFromAPI / rowsToDisplay) +
            filterPageOffset;
        console.log('asking for page' + pageNum);
        if (pageNum === 0) {
            allRowsCount = 0;
        }
        while ((incomingData = await getGridData(pageNum)).length > 0) {
            const filteredData = applyFilters(incomingData, filters);
            accumulatedData = accumulatedData.concat(filteredData);

            if (accumulatedData.length >= rowsToDisplay) {
                break;
            }

            pageNum++;
        }

        allRowsCount += accumulatedData.length;
        if (allRowsCount === 0) {
            //if we didnt find any data show the no data overlay
            gridRef.current!.api.hideOverlay();
            gridRef.current!.api.showNoRowsOverlay();
            params.successCallback([], 0);
        } else {
            // let sortedAccumulatedData = []
            // if(params){
            //   sortedAccumulatedData = sortData(accumulatedData, params.sortModel)
            // } else {
            //   sortedAccumulatedData =  sortData(accumulatedData, [])
            // }

            gridRef.current!.api.hideOverlay();
            if (accumulatedData.length < rowsFromAPI)
                params.successCallback(accumulatedData, allRowsCount);
            else params.successCallback(accumulatedData);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const sortData = (data: any[], sortModel: any) => {
        const sortPresent = sortModel && sortModel.length > 0;

        if (!sortPresent) {
            return data;
        }

        // do an in memory sort of the data, across all the fields
        const resultOfSort = data.slice();
        resultOfSort.sort((a, b) => {
            for (let k = 0; k < sortModel.length; k++) {
                const sortColModel = sortModel[k];
                let valueA;
                let valueB;
                if (['base_dt', 'created_at'].includes(sortColModel.colId)) {
                    //date based sorting
                    valueA = DateTime.fromISO(a[sortColModel.colId]);
                    valueB = DateTime.fromISO(b[sortColModel.colId]);
                } else {
                    //raw text value sorting
                    valueA = a[sortColModel.colId];
                    valueB = b[sortColModel.colId];
                }
                // this filter didn't find a difference, move onto the next one
                if (valueA === valueB) {
                    continue;
                }
                const sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
                if (valueA > valueB) {
                    return sortDirection;
                } else {
                    return sortDirection * -1;
                }
            }

            // no filters found a difference
            return 0;
        });

        return resultOfSort;
    };

    const onSortChange = (event: SortChangedEvent<any>) => {
        const gridColumns = event.columnApi.getAllGridColumns();
        if (gridColumns && gridColumns.length > 0) {
            const sortedColumn = gridColumns.find((column) => column.getSortIndex() === 0);
            if (sortedColumn) {
                setorderBy(sortedColumn.getColId());
                setSortOrder(sortedColumn.getSort() + '');
                event.api.refreshInfiniteCache();
            }
        }
    };
    const gatherFilters = () => {
        console.log(props.dataSetFilter);

        const activeFiltersArray: FilterModel[] | any[] = [];

        //check for applied filters
        let filterInstance = gridRef.current.api.getFilterInstance('vacasa_unit_id');
        let currentModel = filterInstance.appliedModel;
        if (currentModel) {
            console.log('vacasa_unit_id filter found and set');
            const filterObj: FilterModel = {
                value: currentModel.filter,
                column: 'vacasa_unit_id',
                type: currentModel.type,
            };
            activeFiltersArray.push(filterObj);
        }

        filterInstance = gridRef.current.api.getFilterInstance('id');
        currentModel = filterInstance.appliedModel;
        if (currentModel) {
            console.log('id filter found and set');
            const filterObj = {
                value: currentModel.filter,
                column: 'id',
                type: currentModel.type,
            };
            activeFiltersArray.push(filterObj);
        }

        filterInstance = gridRef.current.api.getFilterInstance('lock_number');
        currentModel = filterInstance.appliedModel;
        if (currentModel) {
            console.log('lock_number filter found and set');
            const filterObj = {
                value: currentModel.filter,
                column: 'lock_number',
                type: currentModel.type,
            };
            activeFiltersArray.push(filterObj);
        }

        filterInstance = gridRef.current.api.getFilterInstance('lock_name');
        currentModel = filterInstance.appliedModel;
        if (currentModel) {
            console.log('lock_name filter found and set');
            const filterObj = {
                value: currentModel.filter,
                column: 'lock_name',
                type: currentModel.type,
            };
            activeFiltersArray.push(filterObj);
        }

        filterInstance = gridRef.current.api.getFilterInstance('installed_by');
        currentModel = filterInstance.appliedModel;
        if (currentModel) {
            console.log('installed_by filter found and set');
            const filterObj = {
                value: currentModel.filter,
                column: 'installed_by',
                type: currentModel.type,
            };
            activeFiltersArray.push(filterObj);
        }
        return activeFiltersArray;
    };
    const applyFilters = (lockDataSet: any[], activeFiltersArray: any[]) => {
        let resultOfFilter: any[] = lockDataSet;
        if (activeFiltersArray.length > 0 || props.dataSetFilter !== '') {
            //check if we have any active filters
            console.log('filter data set filter', props.dataSetFilter);
            resultOfFilter = lockDataSet.filter((lockData) => {
                for (let i = 0; i < activeFiltersArray.length; i++) {
                    const columnName = activeFiltersArray[i]['column'];
                    if (!lockData[columnName].includes(activeFiltersArray[i]['value'])) {
                        return false;
                    }
                }
                //this is for the dataset filter that searches the entire row for a value
                if (props.dataSetFilter && props.dataSetFilter !== '') {
                    let found: boolean | any = false;
                    const lockDataValues: string[] = [
                        lockData['vacasa_unit_id'],
                        lockData['id'],
                        lockData['lock_number'],
                        lockData['lock_name'],
                        lockData['installed_by'],
                        lockData['site_address'],
                    ];
                    //console.log(lockDataValues, props.dataSetFilter)
                    found = lockDataValues.find((element) => {
                        return String(element).includes(props.dataSetFilter);
                    });

                    if (found === undefined) {
                        return false;
                    }
                }
                return true;
            });
        }

        return resultOfFilter;
    };

    const clearGridFilters = () => {
        if (gridRef.current.api) {
            let instance = gridRef.current.api.getFilterInstance('vacasa_unit_id');
            gridRef.current.api.destroyFilter(instance);
            instance = gridRef.current.api.getFilterInstance('id');
            gridRef.current.api.destroyFilter(instance);
            instance = gridRef.current.api.getFilterInstance('lock_number');
            gridRef.current.api.destroyFilter(instance);
            instance = gridRef.current.api.getFilterInstance('lock_name');
            gridRef.current.api.destroyFilter(instance);
            instance = gridRef.current.api.getFilterInstance('installed_by');
            gridRef.current.api.destroyFilter(instance);
            instance = gridRef.current.api.getFilterInstance('site_address');
            gridRef.current.api.destroyFilter(instance);
        }
    };

    return (
        <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={100}
            onSortChanged={onSortChange}
            rowModelType={'infinite'}
            datasource={dataSource}
            infiniteInitialRowCount={0}
            enableRangeSelection={true}
            getRowId={props.getRowId}
            suppressMenuHide={true}
            maxConcurrentDatasourceRequests={1}
            cacheBlockSize={rowsFromAPI}
            overlayLoadingTemplate={
                '<Spinner animation="border" role="status"><span className="">Loading...</span></Spinner>'
            }
            overlayNoRowsTemplate={
                '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No Data Found</span>'
            }
        ></AgGridReact>
    );
};

export default AgGrid;
