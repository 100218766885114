import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import '../fontAwesome/css/fontawesome.css'; // Core grid CSS, always needed

export type LockCodeHideProps = any;

const LockCodeHide: React.FC<LockCodeHideProps> = (props) => {
    const [hideCode, setHideCode] = useState(true);
    const hideLockCodeHandler = () => setHideCode(!hideCode);

    //obfuscates the private master code to only show the last 3 digits
    const lockCodeFormatter = (lockCode: string) => {
        if (lockCode) {
            const pmcValue: string = lockCode;
            const pmcLength: number = pmcValue.length;
            if (pmcLength > 3) {
                const pmcHint = pmcValue.slice(pmcLength - 3, pmcLength); //gets the last three chars
                return new Array(pmcLength - 2).join('*') + pmcHint;
            }
            return 'Invalid Code!';
        }
    };

    if (props.value) {
        return (
            <>
                <span>{hideCode ? lockCodeFormatter(props.value) : props.value}</span>
                <Button
                    onClick={hideLockCodeHandler}
                    variant="outline-dark"
                    size="sm"
                    className={'hideCodeBtn'}
                >
                    <i className={hideCode ? ' fas fa-eye' : ' fas fa-eye-slash'}></i>
                </Button>
            </>
        );
    } else {
        return <></>;
    }
};
export default LockCodeHide;
