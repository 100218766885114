import { Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { HotKeys, configure } from 'react-hotkeys';
import '../fontAwesome/css/fontawesome.css'; // Core grid CSS, always needed

export type SearchFieldProps = any;

const SearchField: React.FC<SearchFieldProps> = (props) => {
    const [dataSetFilter, setDataSetFilter] = useState<string>(props.dataSetFilter);
    configure({ ignoreTags: ['select', 'textarea'] });
    const keyMap = {
        SUBMIT: 'Enter',
    };

    const handlers = {
        SUBMIT: (_event: any) => applySearchFilter(_event),
    };

    useEffect(() => {
        setDataSetFilter(props.dataSetFilter);
    }, [props.dataSetFilter]);

    const searchFieldUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            setDataSetFilter(e.target.value);
        } else {
            setDataSetFilter('');
        }
    };

    const applySearchFilter = (event: any) => {
        if (event && event.target.value) {
            //if called from key press event
            props.setDataSetFilter(event.target.value);
        } else {
            //search button was pressed so we can use state
            props.setDataSetFilter(dataSetFilter);
        }
    };

    return (
        <div className="form-group" style={{ display: 'inline' }}>
            <HotKeys keyMap={keyMap} handlers={handlers} component="span">
                <input
                    type="text"
                    value={dataSetFilter}
                    onChange={(e) => searchFieldUpdate(e)}
                    className="form-control"
                    id="tableSearch"
                    placeholder="..."
                    style={{ display: 'inline', width: '300px' }}
                />
                <Button
                    className="btn btn-primary"
                    style={{ display: 'inline', marginBottom: '10px' }}
                    onClick={applySearchFilter}
                >
                    Search
                </Button>
            </HotKeys>
        </div>
    );
};

export default SearchField;
