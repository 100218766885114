class AuthService {
    /**
     * Check if current session contains
     * the authenticated tokens
     */
    static get isAuthenticated() {
        return [AuthService.idpToken].every((condition) => condition);
    }

    static get idpToken() {
        return localStorage.getItem('accessToken');
    }
}

export default AuthService;
