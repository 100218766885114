import { Button, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { DateTime } from 'luxon';
import TkApi from '../tkApi';

import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Optional theme CSS

export type DecodeAccessCodeProps = any;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface GridReadyEvent {
    api: GridApi;
    columnApi: ColumnApi;
    // Event identifier
    type: string;
}

interface DecodedAccessCodeData {
    access_type: string;
    alg: string;
    days: number;
    decrypted: string;
    end_dt: string;
    start_dt: string;
}

const DecodeAccessCode: React.FC<DecodeAccessCodeProps> = (_props) => {
    const TkGridApi = new TkApi();

    const [lockId, setLockId] = useState<string>('');
    const [accessCode, setAccessCode] = useState<string>('');
    const [showDecodedAccessCode, setShowDecodedAccessCode] = useState(false);
    const [decodedAccessCodeData, setDecodedAccessCodeData] = useState<DecodedAccessCodeData>({
        access_type: '',
        alg: '',
        days: 0,
        decrypted: '',
        end_dt: '',
        start_dt: '',
    });
    const [lockData, setLockData] = useState<any>({});

    const decodeAccessCode = async (accessCode: string, lockId: string) => {
        if (lockId && accessCode) {
            const decodeResults = await TkGridApi.decodeAccessCode(lockId, accessCode);
            const lockDataResults = await TkGridApi.getLockById(lockId);
            console.log('what do we get from the decode access code? ', decodeResults);
            console.log('what do we get from the lock data? ', lockDataResults);
            //check response from lock data get call
            if (lockDataResults && !('error' in lockDataResults)) {
                setLockData(lockDataResults);
            } else {
                alert('Invalid Lock Id or Access Code!');
                setDecodedAccessCodeData({
                    access_type: '',
                    alg: '',
                    days: 0,
                    decrypted: '',
                    end_dt: '',
                    start_dt: '',
                });
                return;
            }
            //check response from decode access code call
            if (decodeResults && !('error' in decodeResults)) {
                setDecodedAccessCodeData(decodeResults);
            } else {
                alert('Invalid Lock Id or Access Code!');
                setDecodedAccessCodeData({
                    access_type: '',
                    alg: '',
                    days: 0,
                    decrypted: '',
                    end_dt: '',
                    start_dt: '',
                });
                return;
            }
        } else {
            alert('Must Enter a Lock Id and Access Code!');
            setDecodedAccessCodeData({
                access_type: '',
                alg: '',
                days: 0,
                decrypted: '',
                end_dt: '',
                start_dt: '',
            });
            return;
        }
    };

    const AccessCodeFieldUpdate = (target: Partial<HTMLTextAreaElement>) => {
        if (target.value) {
            setAccessCode(target.value);
        } else {
            setAccessCode('');
        }
    };

    //parses date, expects ISO-8601 format
    const dateFormatter = (dtString: string, timezone = '') => {
        if (dtString) {
            let dateTimeObj = DateTime.fromISO(dtString);
            if (timezone) {
                //if we get passed a timezone for the unit convert to that tz
                dateTimeObj = dateTimeObj.setZone(timezone);
            }
            return (
                dateTimeObj.toLocaleString(DateTime.DATETIME_SHORT) +
                ' ' +
                dateTimeObj.offsetNameShort
            );
        }
        return '';
    };

    const lockIdFieldUpdate = (target: Partial<HTMLTextAreaElement>) => {
        if (target.value) {
            setLockId(target.value);
        } else {
            setLockId('');
        }
    };
    const decodeBtnHandler = () => {
        decodeAccessCode(accessCode, lockId);
    };

    const showDecodeModal = () => {
        setShowDecodedAccessCode(true);
    };

    const hideDecodedAccessCodeHandler = () => {
        //hide the modal and clear the data
        setShowDecodedAccessCode(false);
        setDecodedAccessCodeData({
            access_type: '',
            alg: '',
            days: 0,
            decrypted: '',
            end_dt: '',
            start_dt: '',
        });
        setLockId('');
        setAccessCode('');
    };

    return (
        <span>
            <div className="form-group" style={{ display: 'inline' }}>
                <Button
                    className="btn btn-primary"
                    style={{ display: 'inline', marginBottom: '10px' }}
                    onClick={showDecodeModal}
                >
                    Decode Access Code
                </Button>
            </div>
            <Modal
                show={showDecodedAccessCode}
                onHide={hideDecodedAccessCodeHandler}
                className="access-code-modal modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Access Code Data</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ width: '900px' }}>
                    <div className="container decode-container">
                        <div className="row">
                            <div className="form-group">
                                <label htmlFor="decodeACLockId" className="decode-label">
                                    Lock Id:
                                </label>
                                <input
                                    type="text"
                                    value={lockId}
                                    onInput={(e) => lockIdFieldUpdate(e.target)}
                                    className="form-control"
                                    id="decodeACLockId"
                                    placeholder="..."
                                    style={{ display: 'inline', width: '300px' }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="decodeACAccessCode" className="decode-label">
                                    Access Code:
                                </label>
                                <input
                                    type="text"
                                    value={accessCode}
                                    onInput={(e) => AccessCodeFieldUpdate(e.target)}
                                    className="form-control"
                                    id="decodeACAccessCode"
                                    placeholder="..."
                                    style={{ display: 'inline', width: '300px' }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            {decodedAccessCodeData.decrypted !== '' && (
                                <div className="col decoded-access-code-results">
                                    <div className="form-group">
                                        <label
                                            htmlFor="acAccessType"
                                            className="decode-results-label"
                                        >
                                            <b>Access Type:</b>
                                        </label>
                                        <span id="acAccessType decode-results">
                                            {decodedAccessCodeData.access_type}
                                        </span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="acStart" className="decode-results-label">
                                            <b>Start Date:</b>
                                        </label>
                                        <span id="acStart decode-results">
                                            {dateFormatter(
                                                decodedAccessCodeData.start_dt,
                                                lockData.timezone,
                                            )}
                                        </span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="acEnd" className="decode-results-label">
                                            <b>End Date:</b>
                                        </label>
                                        <span id="acEnd decode-results">
                                            {dateFormatter(
                                                decodedAccessCodeData.end_dt,
                                                lockData.timezone,
                                            )}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-primary"
                        style={{ display: 'inline' }}
                        onClick={decodeBtnHandler}
                    >
                        Decode
                    </Button>
                    <Button variant="secondary" onClick={hideDecodedAccessCodeHandler}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </span>
    );
};

export default DecodeAccessCode;
