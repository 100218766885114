function validateAndGetEnvVar(variable: any): string {
    const value = process.env[variable];
    if (value) return value;
    const message = `Variable ${variable} is undefined or empty`;
    throw new Error(message);
}
// Envs
export const IDP_SCOPES = 'turnkey-lock:read';
export const IDP_GATEWAY = validateAndGetEnvVar('REACT_APP_IDP_GATEWAY');
export const IDP_AUDIENCE = validateAndGetEnvVar('REACT_APP_SMARTHOME_IDP_AUDIENCE');

export const IDP_REDIRECT_URI = validateAndGetEnvVar('REACT_APP_IDP_REDIRECT_URI');
export const IDP_CLIENT_ID: string = validateAndGetEnvVar('REACT_APP_IDP_CLIENT_ID');
export const LOCKWIZARD_API = validateAndGetEnvVar('REACT_APP_LOCKWIZARD_API');

export const LOCKSERVER_NEW = validateAndGetEnvVar('REACT_APP_TKLOCK_SERVER');
export const ADMIN_URL = validateAndGetEnvVar('REACT_APP_ADMIN_URL');

// set env name
// let env: "local" | "stage" | "prod";
// set env name
let env: 'local' | 'dev' | 'stage' | 'prod';
switch (process.env['REACT_APP_CUSTOM_NODE_ENV_SHORT']) {
    case 'local':
        env = 'local';
        break;

    case 'dev':
        env = 'dev';
        break;

    case 'stage':
        env = 'stage';
        break;

    case 'prod':
        env = 'prod';
        break;

    default:
        env = 'stage';
        break;
}

export const ENVIRONMENT = env;
