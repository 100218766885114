import { Button, Modal } from 'react-bootstrap';
import React, { useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { DateTime } from 'luxon';
import LockCodeHide from './lockCodeHide';
import TkApi from '../tkApi';

import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Optional theme CSS

export type FutureCodesModalProps = {
    hideHandler: () => void;
    show: boolean;
    unitId: string | null;
    lockId: string | null;
};

const AccessCodesModal: React.FC<FutureCodesModalProps> = (props) => {
    useEffect(() => {
        console.log('lock id props', props.lockId);
        if (props.lockId) {
            getFutureCodes(props.lockId);
        }
    }, [props.lockId]);

    const TkGridApi = new TkApi();

    const [rowData, setRowData] = useState<any>();

    const gridRef: any = useRef();

    const getFutureCodes = async (lockId: string) => {
        let offset = 0;
        let accumulatedData: any[] = [];
        while (true) {
            const tkData = await TkGridApi.getCodeByLock(lockId, offset, 100);
            accumulatedData = accumulatedData.concat(tkData);
            if (tkData.length < 100) break;
            offset += 100;
        }
        if (accumulatedData && !('error' in accumulatedData)) {
            const formattedData = formatAccessCodeData(accumulatedData);
            if (formattedData) {
                setRowData(formattedData);
            }
        }
    };

    const formatAccessCodeData = (data: any[]): void[] => {
        const timeLimit = DateTime.local().plus({ days: -90 });
        const dataFiltered = data.filter((accessCodeData) => {
            const createdAtDT = DateTime.fromISO(accessCodeData.created_at);
            if (timeLimit >= createdAtDT) {
                //we only want access codes from ninety days ago
                return false;
            } else {
                return true;
                //return true;
            }
        });
        return dataFiltered.map((accessCodeData, _index) => {
            return {
                ...accessCodeData,
                access_type: accessCodeData.params.access_type,
                installed_by: accessCodeData.lock.installed_by,
                lock_id: accessCodeData.lock.id,
            };
        });
    };

    //parses date, expects ISO-8601 format
    const dateFormatter = (params: any) => {
        const dateParts = params.value.split('T');

        const dateTimeObj = DateTime.fromISO(`${dateParts[0]}T${dateParts[1].split('-')[0]}`);
        dateTimeObj.toUTC();

        return dateTimeObj.toFormat('L/dd/yyyy h:mma');
    };
    const dateFormatterTimeZone = (params: any) => {
        const dateTimeObj = DateTime.fromISO(params.value);
        return dateTimeObj.toLocaleString(DateTime.DATETIME_SHORT);
    };

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: 'Access Code',
            field: 'code',
            width: 170,
            cellRenderer: LockCodeHide,
            filterParams: {
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
            },
        },
        {
            headerName: 'Category',
            field: 'access_type',
            width: 130,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
            },
        },
        { headerName: 'Status', field: 'status', width: 80 },
        {
            headerName: 'Start Date',
            field: 'start_dt',
            width: 180,
            valueFormatter: dateFormatter,
            sortable: true,
        },
        {
            headerName: 'End Date',
            field: 'end_dt',
            width: 180,
            valueFormatter: dateFormatter,
            sortable: true,
        },
        {
            headerName: 'Generated Time',
            field: 'created_at',
            width: 180,
            valueFormatter: dateFormatterTimeZone,
            sortable: true,
        },
    ]);

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.hideHandler}
                className="access-code-modal modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Access Codes ({props.unitId ? props.unitId : 'Unit ID not found'})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ width: '1015px' }}>
                    <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                        ></AgGridReact>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.hideHandler}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AccessCodesModal;
